/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/Banner-School.jpg";
import leftIcon from "../../../images/Left Button.svg";
import inline from "../../../images/Inline-school.jpg";
const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Australian Schools Plus| Partners in Performance | Global Management Consultancy"
      description={
        "Explore our case study about helping Schools Plus, an NGO focused on closing the education gap, refresh their donor outreach strategy."
      }
    >
      <Hero
        title="Australian Schools Plus"
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "Schools Plus exists to help close the education gap caused by disadvantage. They work with teachers and schools in disadvantaged areas to empower them with funding, coaching and resources to implement initiatives that will help their students succeed. ",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "Schools Plus exists to help close the education gap caused by disadvantage. They empower schools in disadvantaged communities with funding, coaching and resources to create lasting change, which helps students succeed.",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "Their vision is that all young Australians reach their potential through access to a great education. ",
            heading: [],
          },
        ]}
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        articlewithContent="YES"
        Articletitle="Challenge faced by organisation "
        heading={[
          {
            children: [
              {
                text: "An important part of Schools Plus’ network is their donor base, who are essentially for supporting many of the projects to help schools in disadvantaged communities.  ",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "To help inform the future donor outreach strategy and overall organisational strategy, Schools Plus engaged PiP to conduct a survey to understand: ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "1. What perceptions donors have of the organisation, especially in terms of trust, credibility and delivery of meaningful student outcomes",
            heading: [],
          },
          {
            id: "",
            title: "2. How to best engage donors and make them feel valued ",
            heading: [],
          },
          {
            id: "",
            title:
              "To date, minimal studies and surveys have been conducted to have a comprehensive understanding of what donors think despite their high importance for supporting our client’s vision.  ",
            heading: [],
          },
        ]}
      />
      {/* <ValuesCollection
        key=""
        title="Challenge faced by organisation"
        changeDesign="YES"
        styleclass="pt-5px"
        heading={[
          {
            children: [
              {
                text: "An important part of Schools Plus’ network is their donor base, who are essentially for supporting many of the projects to help schools in disadvantaged communities.  ",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "To help inform the future donor outreach strategy and overall organisational strategy, Schools Plus engaged PiP to conduct a survey to understand: ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "1. What perceptions donors have of the organisation, especially in terms of trust, credibility and delivery of meaningful student outcomes",
            heading: [],
          },
          {
            id: "",
            title: "2. How to best engage donors and make them feel valued ",
            heading: [],
          },
          {
            id: "",
            title:
             "To date, minimal studies and surveys have been conducted to have a comprehensive understanding of what donors think despite their high importance for supporting our client’s vision.",
            heading: [],
          },
        ]}
      /> */}
      <ValuesCollection
        key=""
        title="How we helped "
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        className="bg-light-blue"
        statements={[
          {
            id: "",
            title:
              "Developed the donor survey questions that focused on perceptions and engagement",
            heading: [],
          },
          {
            id: "",
            title:
              "Analysed donor survey results, providing important insights for their various strategy plans ",
            heading: [],
          },
          {
            id: "",
            title:
              "Recommended key actions for improving donor relations and reporting strategy ",
            heading: [],
          },
        ]}
      />
      <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        butlletdesign="YES"
        heading={[
          {
            children: [
              {
                text: "Refreshed donor outreach strategy so it is relevant, insightful, and based on data from 39 donors.",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "Developed a set of tangible actions that can be easily managed with limited resources. ",
            heading: [],
          },
          {
            id: "",
            title:
              "Developed a model for analysing results that can be used for future surveys. ",
            heading: [],
          },
          {
            id: "",
            title:
              "Established a baseline for donor perceptions and engagement that can be extended into future performance management initiatives. ",
            heading: [],
          },
        ]}
      />
      <p className="col-span-12 col-start-2 text-center p-20px">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a href="https://www.schoolsplus.org.au/" className="text-dark-blue">
            https://www.schoolsplus.org.au/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
